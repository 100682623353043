import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row px-2 py-2 text-light text-center" }
const _hoisted_2 = { class: "w-1/12 pt-1.5" }
const _hoisted_3 = { class: "w-5/12" }
const _hoisted_4 = { class: "w-4/12 text-primary-blue font-bold" }
const _hoisted_5 = { class: "w-2/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "rounded-full w-3 h-3 m-auto block",
        style: _normalizeStyle(`background-color: ${_ctx.color};`)
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.countryName), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.parcelsCount), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(`${_ctx.parcelsPercentage}${_ctx.$t("report.parcels_per_country.labels.percent")}`), 1)
  ]))
}