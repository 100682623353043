
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportsCountriesLegendItem",
  props: {
    color: {
      type: String,
      required: true,
    },
    countryName: {
      type: String,
      required: true,
    },
    parcelsCount: {
      type: String,
      required: true,
    },
    parcelsPercentage: {
      type: String,
      required: true,
    },
  },
});
