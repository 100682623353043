import axiosClient from "@/axios";
import { AxiosPromise } from "axios";
import { ReportsResponse } from "@/models/ReportsResponse";

const reportsService = {
  getStartDates(): AxiosPromise<Record<string, string>> {
    return axiosClient.get("/parcel_reports/start_dates");
  },
  getReportTypes(): AxiosPromise<Record<number, string>> {
    return axiosClient.get("/parcel_reports/types");
  },
  get(startDate: string, reportType: string): AxiosPromise<ReportsResponse> {
    return axiosClient.get(
      `/parcel_reports?start_date=${startDate}&type=${reportType}`
    );
  },
  post(startDate: string, reportType: string): AxiosPromise<void> {
    return axiosClient.post(
      `/parcel_reports?start_date=${startDate}&type=${reportType}`
    );
  },
};

export default reportsService;
