import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsDetailItem = _resolveComponent("ReportsDetailItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.details, (row, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex flex-row",
        key: `report_detail_row_${index}`
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (detail, index) => {
          return (_openBlock(), _createBlock(_component_ReportsDetailItem, {
            value: detail.value,
            label: detail.label,
            "icon-name": detail.iconName,
            key: `report_detail_detail_${index}`
          }, null, 8, ["value", "label", "icon-name"]))
        }), 128))
      ]))
    }), 128))
  ]))
}