
import { defineComponent, nextTick, PropType, ref } from "vue";
import { ReportsCountry, ReportsResponse } from "@/models/ReportsResponse";
import ReportsCountriesLegendItem from "@/components/reports/ReportsCountries/ReportsCountriesLegendItem.vue";
import Chart, { ChartItem } from "chart.js/auto";
import _ from "lodash";
import { onMounted } from "@vue/runtime-core";

export type ReportsCountriesMethods = {
  prepareCountriesChartData: () => void;
  createCountriesChart: (canvas: Element) => void;
};

export type ReportsCountriesLegend = {
  color: string;
  countryName: string;
  parcelsCount: string;
  parcelsPercentage: string;
  intermediarySum?: number;
}[];

export type ReportsCountriesState = {
  data: ReportsResponse;
  legend: ReportsCountriesLegend;
};

export default defineComponent({
  name: "ReportsCountries",
  components: { ReportsCountriesLegendItem },
  props: {
    reportData: {
      type: Object as PropType<ReportsResponse>,
      required: true,
    },
  },
  setup(props) {
    const methods = {} as ReportsCountriesMethods;
    const state = ref<ReportsCountriesState>({
      data: props.reportData,
      legend: [],
    });

    methods.prepareCountriesChartData = () => {
      const colors = ["#2997AB", "#8BA1E8", "#1286C4", "#0062DA", "#185E6E"];

      state.value.legend = _.reduce(
        _.sortBy(
          _.get(state.value.data, "report_per_countries", []),
          (country: ReportsCountry) => {
            return -_.get(country, "parcels_count", 0);
          }
        ),
        (topFive: ReportsCountriesLegend, countryData) => {
          const parcelsCount = _.get(countryData, "parcels_count", 0);
          const parcelsPercentage =
            (100 * parcelsCount) / _.get(state.value.data, "parcels_count", 1);
          if (topFive.length < 4) {
            topFive.push({
              color: colors[topFive.length],
              countryName: _.get(countryData, "country_name", ""),
              parcelsCount: parcelsCount.toString(),
              parcelsPercentage: parcelsPercentage.toFixed(0),
              intermediarySum: parcelsCount,
            });
          } else if (topFive.length < 5) {
            topFive.push({
              color: colors[topFive.length],
              countryName: "Other",
              parcelsCount: parcelsCount.toString(),
              parcelsPercentage: parcelsPercentage.toFixed(0),
              intermediarySum: parcelsCount,
            });
          } else {
            topFive[4].intermediarySum =
              (topFive[4].intermediarySum || 0) + parcelsCount;
            topFive[4].parcelsCount = (
              topFive[4].intermediarySum || 0
            ).toString();
            topFive[4].parcelsPercentage = (
              (100 * topFive[4].intermediarySum) /
              _.get(state.value.data, "parcels_count", 1)
            ).toFixed(0);
          }
          return topFive;
        },
        [] as ReportsCountriesLegend
      );
    };

    methods.createCountriesChart = (canvas: Element): void => {
      new Chart(canvas as ChartItem, {
        type: "pie",
        data: {
          labels: _.map(state.value.legend, "countryName"),
          datasets: [
            {
              data: _.map(state.value.legend, "parcelsCount"),
              backgroundColor: _.map(state.value.legend, "color"),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    };

    onMounted(async () => {
      methods.prepareCountriesChartData();
      await nextTick();
      const canvas = document.getElementById("reportsCountriesCanvas");
      if (canvas) {
        methods.createCountriesChart(canvas);
      }
    });

    return {
      state,
      methods,
    };
  },
});
