import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-1/2 pl-4 mb-6 h-28" }
const _hoisted_2 = { class: "bg-white rounded flex justify-between px-6 py-3 h-full" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-big font-bold text-primary-blue pt-1.5" }
const _hoisted_5 = { class: "flex bg-light-green w-16 text-primary-blue items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.label)), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.value), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_icon, { name: _ctx.iconName }, null, 8, ["name"])
      ])
    ])
  ]))
}