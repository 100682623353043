
import { defineComponent, PropType } from "vue";
import ReportsSupplierSummary from "@/components/reports/ReportsSuppliers/ReportsSupplierSummary.vue";
import { ReportsResponse } from "@/models/ReportsResponse";

export default defineComponent({
  name: "ReportsSuppliers",
  components: {
    ReportsSupplierSummary,
  },
  props: {
    reportData: {
      type: Object as PropType<ReportsResponse>,
      required: true,
    },
  },
});
