
import { Component, defineComponent, onMounted, ref, shallowRef } from "vue";
import Spinner from "@/components/ui/Spinner.vue";
import reportsService from "@/services/reports.service";
import { PossibleValues, SelectOption } from "@/common/FormTools";
import _ from "lodash";
import { FormElementData } from "@/models/FormElementData";
import FormInput from "@/components/form/inputs/FormInput.vue";
import { FormElementState } from "@/models/FormElementState";
import NotExistingReport from "@/components/reports/ReportsErrors/NotExistingReport.vue";
import GeneralError from "@/components/reports/ReportsErrors/GeneralError.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import ReportBeingPrepared from "@/components/reports/ReportsErrors/ReportBeingPrepared.vue";
import { ReportsResponse } from "@/models/ReportsResponse";
import ReportsSuppliers from "@/components/reports/ReportsSuppliers/ReportsSuppliers.vue";
import ReportsDetails from "@/components/reports/ReportsDetails/ReportsDetails.vue";
import ReportsParcels from "@/components/reports/ReportsParcels/ReportsParcels.vue";
import ReportsCountries from "@/components/reports/ReportsCountries/ReportsCountries.vue";
import { EventBus } from "@/common/Tools";

export type ReportsListMethods = {
  startDateChange: (fieldId: string, newValue: PossibleValues) => void;
  getStartDateOptions: () => Promise<void>;
  reportTypeChange: (fieldId: string, newValue: PossibleValues) => void;
  getReportTypeOptions: () => Promise<void>;
  getReportData: () => Promise<void>;
  createReport: () => Promise<void>;
  closeModal: () => void;
  checkSelectedCustomer: (selectedCustomer: unknown) => void;
};

export type ReportsListState = {
  isLoading: boolean;
  isParentCustomer: boolean;
  refreshDisabled: boolean;
  showModal: boolean;
  modalMessage: string;
  startDate: string;
  startDateElementData: FormElementData;
  startDateInitialState: FormElementState;
  reportType: string;
  reportTypeElementData: FormElementData;
  reportTypeInitialState: FormElementState;
  reportData?: ReportsResponse;
  backendError?: Component;
};

export default defineComponent({
  name: "ReportsList",
  components: {
    ReportsDetails,
    ReportsSuppliers,
    ReportsParcels,
    ReportsCountries,
    Modal,
    DefaultButton,
    FormInput,
    Spinner,
  },
  setup() {
    const methods = {} as ReportsListMethods;
    const state = ref<ReportsListState>({
      isLoading: true,
      isParentCustomer: false,
      refreshDisabled: false,
      showModal: false,
      modalMessage: "",
      startDate: "",
      startDateElementData: {
        id: "start_date",
        type: "select",
        options: () => [],
        disabledEmptyOption: true,
        class: "flex flex-col items-center",
      },
      startDateInitialState: {},
      reportType: "",
      reportTypeElementData: {
        id: "report_type",
        type: "select",
        options: () => [],
        disabledEmptyOption: true,
        class: "flex flex-col items-center",
      },
      reportTypeInitialState: {},
      reportData: undefined,
      backendError: undefined,
    });

    methods.reportTypeChange = (fieldId: string, newValue: PossibleValues) => {
      if (newValue) {
        state.value.reportType = newValue as string;
        methods.getReportData();
      }
    };

    methods.getReportTypeOptions = async (): Promise<void> =>
      reportsService.getReportTypes().then((response) => {
        const extractedOptions = [
          {
            value: response.data[0],
            label: "Regular",
          },
          {
            value: response.data[1],
            label: "Overall",
          },
        ];
        const defaultValue = extractedOptions[0].value as string;
        state.value.reportTypeElementData.options = () => extractedOptions;
        state.value.reportType = defaultValue;
        state.value.reportTypeInitialState.value = defaultValue;
      });

    methods.startDateChange = (fieldId: string, newValue: PossibleValues) => {
      if (newValue) {
        state.value.startDate = newValue as string;
        methods.getReportData();
      }
    };

    methods.getStartDateOptions = async (): Promise<void> =>
      reportsService.getStartDates().then((response) => {
        const extractedOptions = _.transform(
          response.data,
          (options: SelectOption[], label: string, value: string) => {
            options.push({
              value,
              label,
            });
          },
          [] as SelectOption[]
        );
        const defaultValue = extractedOptions[0].value as string;
        state.value.startDateElementData.options = () => extractedOptions;
        state.value.startDate = defaultValue;
        state.value.startDateInitialState.value = defaultValue;
      });

    methods.getReportData = async (): Promise<void> => {
      state.value.isLoading = true;
      state.value.refreshDisabled = false;
      state.value.backendError = undefined;
      state.value.reportData = undefined;
      return reportsService
        .get(state.value.startDate, state.value.reportType)
        .then((response) => {
          if (response.data.new_parcels_being_processed) {
            state.value.refreshDisabled = true;
            state.value.backendError = shallowRef(ReportBeingPrepared);
          } else {
            state.value.reportData = response.data;
          }
        })
        .catch((response) => {
          if (response.response.status === 404) {
            state.value.backendError = shallowRef(NotExistingReport);
          } else {
            state.value.backendError = shallowRef(GeneralError);
          }
        })
        .finally(() => {
          state.value.isLoading = false;
        });
    };

    methods.createReport = async (): Promise<void> => {
      state.value.isLoading = true;
      state.value.showModal = false;
      state.value.modalMessage = "";
      reportsService
        .post(state.value.startDate, state.value.reportType)
        .then(() => {
          state.value.modalMessage = "report.modal.success_message";
          state.value.showModal = true;
        })
        .catch((response) => {
          if (response.response.status === 400) {
            state.value.modalMessage = "report.modal.already_preparing_error";
          } else {
            state.value.modalMessage = "report.modal.general_error";
          }
          state.value.showModal = true;
        })
        .finally(() => {
          state.value.isLoading = false;
        });
    };

    methods.closeModal = () => {
      state.value.showModal = false;
      methods.getReportData();
    };

    methods.checkSelectedCustomer = (selectedCustomer) => {
      state.value.isParentCustomer = !selectedCustomer;
    };

    onMounted(async () => {
      await methods.getReportTypeOptions();
      await methods.getStartDateOptions();
      await methods.getReportData();
    });

    EventBus.emit("checkSelectedCustomer");

    EventBus.on("selectedCustomer", methods.checkSelectedCustomer);

    return {
      state,
      methods,
    };
  },
});
