
import { defineComponent } from "vue";
import Icon from "@/components/ui/Icon.vue";

export default defineComponent({
  name: "ReportsDetailItem",
  components: { Icon },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
});
