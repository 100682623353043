
import { defineComponent, onMounted, PropType, ref } from "vue";
import { ReportsResponse } from "@/models/ReportsResponse";
import Chart, { ChartItem } from "chart.js/auto";
import _ from "lodash";

export type ReportsParcelsMethods = {
  createParcelsChart: (canvas: Element) => void;
};

export type ReportsParcelsState = {
  data: number[];
  isEmpty: boolean;
};

export default defineComponent({
  name: "ReportsParcels",
  props: {
    reportData: {
      type: Object as PropType<ReportsResponse>,
      required: true,
    },
  },
  setup(props) {
    const methods = {} as ReportsParcelsMethods;
    const reportPerWeekdays = ref(props.reportData.report_per_weekdays);
    const state = ref<ReportsParcelsState>({
      data: [
        reportPerWeekdays.value?.parcels_per_monday ?? 0,
        reportPerWeekdays.value?.parcels_per_tuesday ?? 0,
        reportPerWeekdays.value?.parcels_per_wednesday ?? 0,
        reportPerWeekdays.value?.parcels_per_thursday ?? 0,
        reportPerWeekdays.value?.parcels_per_friday ?? 0,
        reportPerWeekdays.value?.parcels_per_saturday ?? 0,
        reportPerWeekdays.value?.parcels_per_sunday ?? 0,
      ],
      isEmpty: !_.sum(_.values(_.omit(reportPerWeekdays.value, "id"))),
    });

    methods.createParcelsChart = (canvas: Element) => {
      new Chart(canvas as ChartItem, {
        type: "bar",
        data: {
          labels: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          datasets: [
            {
              label: "Parcels",
              backgroundColor: "#0095DA",
              data: state.value.data,
              borderRadius: 4,
            },
          ],
        },
        options: {
          aspectRatio: 2.5,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: true,
              grid: {
                display: false,
              },
              ticks: {
                callback: (tickValue: number | string) =>
                  !(+tickValue % 1) ? tickValue.toString() : "",
              },
            },
            x: {
              display: true,
              grid: {
                display: true,
              },
            },
          },
        },
      });
    };

    onMounted(() => {
      const canvas = document.getElementById("reportsParcelsCanvas");
      if (canvas) {
        methods.createParcelsChart(canvas);
      }
    });

    return {
      state,
      methods,
    };
  },
});
