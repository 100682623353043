
import { defineComponent, PropType, ref } from "vue";
import { ReportsResponse } from "@/models/ReportsResponse";
import ReportsDetailItem from "@/components/reports/ReportsDetails/ReportsDetailItem.vue";

export type ReportsDetailsState = {
  details: {
    value: string;
    label: string;
    iconName: string;
  }[][];
};

export default defineComponent({
  name: "ReportsDetails",
  components: {
    ReportsDetailItem,
  },
  props: {
    reportData: {
      type: Object as PropType<ReportsResponse>,
      required: true,
    },
  },
  setup(props) {
    const state = ref<ReportsDetailsState>({
      details: [
        [
          {
            value: props.reportData.parcels_count?.toString() || "-",
            label: "report.statistics.parcels",
            iconName: "package",
          },
          {
            value: props.reportData.bulk_parcels_count?.toString() || "-",
            label: "report.statistics.bulk_parcels",
            iconName: "transaction",
          },
        ],
        [
          {
            value:
              (props.reportData.average_shipping_time?.toFixed(2) || "-") +
              " days",
            label: "Average Shipping Time",
            iconName: "clock",
          },
          {
            value:
              props.reportData.report_per_countries?.length.toString() || "-",
            label: "report.statistics.countries_number",
            iconName: "truck",
          },
        ],
        [
          {
            value: props.reportData.returns_count?.toString() || "-",
            label: "report.statistics.returns",
            iconName: "return",
          },
          {
            value: (props.reportData.returns_percentage?.toFixed(2) || "-") + " %",
            label: "report.statistics.returns_percentage",
            iconName: "percentage",
          },
        ],
        [
          {
            value:
              (props.reportData.ordered_same_day_percentage?.toFixed(2) ||
                "-") + " %",
            label: "Orders fulfilled same day",
            iconName: "truck",
          },
          {
            value:
              (props.reportData.average_parcel_weight?.toFixed(2) || "-") +
              " kg",
            label: "report.statistics.avg_parcel_weight",
            iconName: "kilograms",
          },
        ],
      ],
    });
    return {
      state,
    };
  },
});
