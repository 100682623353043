
import { defineComponent, PropType, ref } from "vue";
import { ReportsSupplier } from "@/models/ReportsResponse";

export default defineComponent({
  name: "ReportsSupplierSummary",
  props: {
    supplier: {
      type: Object as PropType<ReportsSupplier>,
      required: true,
    },
  },
  setup() {
    const state = ref({
      isExpanded: false,
    });
    return {
      state,
    };
  },
});
